import MainRoutes from "routers";
import "react-input-range/lib/css/index.css";
import "./App.css";


function App() {
  return (
    <MainRoutes />
  );
}

export default App;
