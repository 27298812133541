export const LOADING = "LOADING";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_MIN_MAX = "GET_PRODUCTS_MIN_MAX";
export const ADD_PRODUCT_TO_BASKET = "ADD_PRODUCT_TO_BASKET";
export const SUBTRACT_PRODUCT_FROM_BASKET = "SUBTRACT_PRODUCT_FROM_BASKET";
export const REMOVE_PRODUCT_FROM_BASKET = "REMOVE_PRODUCT_FROM_BASKET";
export const ADD_A_PRODUCT_TO_BASKET = "ADD_A_PRODUCT_TO_BASKET";
export const MAX_MIN_HANDLE = "MAX_MIN_HANDLE";
export const GET_A_PRODUCT = "GET_A_PRODUCT";
export const REVIEW_PRODUCT = "REVIEW_PRODUCT";
export const CAN_PUT_REVIEW_PRODUCT = "CAN_PUT_REVIEW_PRODUCT";
export const PRICE_RANGE = "PRICE_RANGE";
export const GLOBAL_SEARCH_INPUT_CHANGED = "GLOBAL_SEARCH_INPUT_CHANGED";
export const CLEAR_CATEGORY = "CLEAR_CATEGORY";
export const CLEAR_BASKET = "CLEAR_BASKET";
export const SEL_CATEGORIES = "SEL_CATEGORIES";
