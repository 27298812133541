import "./style.css";
import React from "react";

function InfoCard() {
  return (
    <div className="info__card">
      <h2>Свежие <br /> продукты <br /> на каждый <br /> день!</h2>
    </div>
  );
}

export default InfoCard;
